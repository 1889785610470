import { navigate } from "@reach/router"
import { isLoggedIn } from "../utils/auth"
import fire from "../utils/fire"

// import ScrollToTop from '../ScrollToTop'
import { useEffect, useState } from "react"
// import { useLoggedInStatus } from "../utils/woodtools"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
	useEffect(() => {
		// alert("PV " + isLoggedIn())
		if (!isLoggedIn() && location.pathname !== `/login`) {
			// return <Redirect from="/" to="/login" />
			navigate(`/login`, { replace: true })
			// alert("redirect login")
		}
	}, [])

	// const logged = useLoggedInStatus()

	// !logged && navigate(`/login`, { replace: true })

	useEffect(() => {
		let unsub = fire.auth().onAuthStateChanged((user) => {
			!user && navigate(`/login`, { replace: true })
			// !user && alert("redirect login")
		})
		return () => unsub()
	}, [])

	// if (!isLoggedIn() && location.pathname !== `/login`) {
	// 	// If we’re not logged in, redirect to the home page.
	// 	return <Redirect from="/" to="/login" />
	// } else {
	// 	return [<ScrollToTop key="scrollTop"/>,<Component {...rest} key="comp"/>]
	// }
	return <Component {...rest} />

	// return [<ScrollToTop key="scrollTop"/>,<Component {...rest} key="comp"/>]
}

// PrivateRoute.propTypes = {
//   component: PropTypes.any.isRequired,
// }

export default PrivateRoute

import React , { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import Hidden from '@material-ui/core/Hidden';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import { getWoodsGroupByCategories } from "../utils/woodtools"

const useStyles = makeStyles((theme) => ({
	fab: {
		position: "fixed",
		bottom: "12px",
		right: "60px",
		zIndex: "999",
		// [theme.breakpoints.down('lg')]: {
		// 	bottom: "50px",
		// 	right: "50px",
		// },
		[theme.breakpoints.down('md')]: {
			// bottom: "40px",
			right: "40px",
		},
		[theme.breakpoints.down('xs')]: {
			// bottom: "35px",
            bottom: "12px",
			right: "15px",
		},
	},
    select: {
        position: "fixed",
        zIndex: 99999,
        width: "auto",
        bottom: "12px",
        [theme.breakpoints.up('lg')]: {
			left: "auto !important",
            width: "410px",
			right: "0px",
		},
		[theme.breakpoints.up('md')]: {
			left: "254px",
			right: "15px",
		},
        [theme.breakpoints.down('sm')]: {
            left: "15px",
			right: "134px",
		},
		[theme.breakpoints.down('xs')]: {
            left: "15px",
			right: "86px",
		},
    },
    bar: {
        position: "fixed",
        zIndex: 99999,
        width: "auto",
        height: "81px",
        right: "0",
        left: "0",
        bottom: "0",
        borderRadius: "19px 19px 0 0",
        border: "1px #dedede solid",
        [theme.breakpoints.up('lg')]: {
			left: "auto !important",
            width: "439px",
			right: "0px",
            borderRadius: "19px 0 0 0 !important",
		},
        [theme.breakpoints.up('md')]: {
			left: "239px",
			right: "0px",
            borderRadius: "0 0 0 0",
		},
    }
}));

/**
 * 
 * @param {function} getOpenDrawer props
 * @param {function} getType props
 * @param {function} type props
 * 
 * @returns Type Selector
 */
const TypeSelect = (props) => {
	const classes = useStyles();

    const handleSelectChantier = (e) => props.getType(e.target.value)
    
    const handleOpen = () => window.location.hash = "#openDialog"

    return (
        <>
            <Hidden mdUp>
                <Fab color="secondary" aria-label="add" style={{zIndex: 999999, bottom: "12px"}} className={classes.fab} onClick={handleOpen} elevation={0}>
                    <FormatListBulletedIcon />
                </Fab>
            </Hidden>
            
            <Paper className={classes.bar} elevation={10}>
            </Paper>

            <Paper className={classes.select} elevation={0}>
                <Select
                    native
                    value={props.type}
                    onChange={handleSelectChantier}
                    variant="outlined"
                    fullWidth
                >
                    <option value="">Tous les produits</option>
                    {Object.entries(getWoodsGroupByCategories()).map(([categorie, bois],i) => {
                        return (
                            <optgroup label={categorie} key={i}>
                                {Object.entries(bois).map(([code, infos]) => <option value={code} key={code}>[{code}] {infos.name}</option>)}
                            </optgroup>
                        )
                    })}
                </Select>
            </Paper>

        </>
    )
}
export default TypeSelect

import React, { useState, useEffect } from "react"
import { navigate } from '@reach/router';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { setUser, isLoggedIn, notifyLoginChangeListener } from "../utils/auth"
import firebase from "firebase/app"

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
// import { useLoggedInStatus } from "../utils/woodtools";


const useStyles = makeStyles((theme) => ({
	toolbar: theme.mixins.toolbar,
}))

const Login = () => {
	const classes = useStyles();
	const [configUi, setConfigUi] = useState(null);
	// const isLoggedIn = useLoggedInStatus()

	useEffect(()=> {
		// alert(isLoggedIn())
		// alert("login "+ isLoggedIn())
		if (isLoggedIn()) {
			navigate(`/`)
		}
		setConfigUi(getUiConfig())
	}, [])
	
	function getUiConfig() {
		return {
			signInFlow: 'popup',
			signInOptions: [
				firebase.auth.GoogleAuthProvider.PROVIDER_ID,
				// firebase.auth.EmailAuthProvider.PROVIDER_ID
			],
			signInSuccessUrl: '/',
			callbacks: {
				signInSuccessWithAuthResult: (result) => {
					setUser(result.user);
					// notifyLoginChangeListener()
					navigate('/');
				}
			}
		};
	}
	
	if (!configUi) {
		return <p>Chargement...</p>
	}
	
	return (
		<Container>
			<div className={classes.toolbar} />
			<div className={classes.toolbar} />
			<Paper style={{padding: "14px 0"}}>
				{/* <p>Please sign-in to access to the private route:</p> */}
				<StyledFirebaseAuth uiConfig={configUi} firebaseAuth={firebase.auth()}/>
				{/* <div id="logui"></div> */}
			</Paper>
		</Container>
	);
		
}

export default Login
	
import { useEffect, useState, useRef } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Router } from "@reach/router"
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import Login from "./Login"
import Appbar from "./Appbar";
import MainComponent from "./MainComponent"
import PrivateRoute from "./PrivateRoute";
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		// padding: theme.spacing(3),
		// [theme.breakpoints.down('sm')]: {
		// 	padding: theme.spacing(2),
        //     paddingLeft: '0px',
        //     paddingRight: '0px',
        // },
	}
}));

const Layout = ({ children }) => {
	const classes = useStyles();

	const [newVersionAvailable, setNewVersionAvailable] = useState(false)
	const [waitingWorker, setWaitingWorker] = useState({})


	const onServiceWorkerUpdate = (registration) => {
		console.log("REG", registration)
		setWaitingWorker(registration.waiting)
		setNewVersionAvailable(true)
	}
	const updateServiceWorker = () => {
        waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' })
        setNewVersionAvailable(false)
        window.location.reload()
	}
	useEffect(() => {
		serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate})
	}, []);

    return (
		<div className={classes.root}>

			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				style={{bottom: "90px"}}
				open={newVersionAvailable}
				message="Nouvelle version disponible !"
				action={
					<Button color="secondary" size="small" onClick={() => updateServiceWorker()}>
						Mettre à jour
					</Button>
				}
			/>

            <Appbar />
			<Router style={{width: "100%", display: "flex"}}>
				<Login path="/login"/>
				<PrivateRoute default path="/" component={MainComponent} />
			</Router>
            {/* <MainComponent /> */}
		</div>
    )
}
export default Layout

import React , { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import MUIDrawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Room from '@material-ui/icons/Room';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { getUnit } from "../utils/woodtools"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
    listItem: {
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}));

const DrawerContainer = (props) => {
	const classes = useStyles();
	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
	const [drawer, setDrawer] = useState();

    useEffect(() => {
        setDrawer(makeDrawer())
    }, [props.chantiers, props.mapRef, props.stocks])

    const handleClose = () => {
        // var noHashURL = window.location.href.replace(/#.*$/, '');
        // window.history.replaceState('', document.title, noHashURL) 
        // window.history.back()
        window.location.hash = "_"
    }

    const makeDrawer = () => {
		return (
            <>
                <div className={classes.toolbar} />
                <Divider />
				<List style={{overflowY: "auto"}} dense>
                    {props.chantiers && props.chantiers.map((val, i) => {
                        return (
                            (!["", undefined].includes(val.latitude) &&
                            !["", undefined].includes(val.longitude) &&
                            !val.soldOut)
                            && [
                                <ListItem button className={classes.listItem} key={val.idDoc} onClick={() => handleSelectChantier(val.idDoc)} >
                                    <ListItemIcon>
                                        <Room />
                                    </ListItemIcon>
                                    <ListItemText primary={val.numeroChantier} 
                                    secondary={
                                        <>
                                            <Typography
                                                component="span"
                                                variant="caption"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {val.proprietaire}
                                            </Typography>
                                            <br/>
                                            {
                                                props.stocks[val.idDoc] && props.currentType !== "" ?
                                                (props.stocks[val.idDoc][props.currentType].stock+" / "+props.stocks[val.idDoc][props.currentType].volumeInitial+" "+getUnit(props.currentType, true))
                                                : ""
                                            }
                                        </>
                                    }
                                    // secondary={
                                    //     props.stocks[val.idDoc] &&
                                    //     props.currentType !== "" ?
                                    //     (props.stocks[val.idDoc][props.currentType].stock+" / "+props.stocks[val.idDoc][props.currentType].volumeInitial+" "+getUnit(props.currentType, true)) :
                                    //     (props.currentType === "" && val.proprietaire)
                                    // }
                                    />
                                </ListItem>,
                                <Divider key={val.idDoc+i} />
                            ]
                        )
                    })}
                    <div style={{height: "130px"}}/>
				</List>
            </>
		)
	}


    const handleSelectChantier = (e) => {
        handleClose()
        //simple
        props.markersRef.current[e].openPopup()
        let position = [ props.chantiers.find(x=> x.idDoc === e).latitude, props.chantiers.find(x=> x.idDoc === e).longitude ] //arr[0] + 0.006
        // console.log(props)
        props.mapRef.flyTo(position, 14)
        //flexible
        // setTimeout(() => {
        //     console.log({ ...props.markersRef.current[e]._popup })
        //     var px = props.mapRef.project(props.markersRef.current[e]._popup._source._latlng);
        //     px.y -= props.markersRef.current[e]._popup._contentNode.clientHeight/2;
        //     console.log(props.markersRef.current[e]._popup._contentNode.clientHeight)
        //     props.mapRef.flyTo(props.mapRef.unproject(px), 14);
        // }, 100)
    }

    return (
        <>
            <nav className={classes.drawer}>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                {/* <Hidden mdUp>
                    <SwipeableDrawer
                    disableSwipeToOpen
                    disableBackdropTransition={!iOS}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    // variant="permanent"
                    anchor="left"
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    onOpen={() => setOpenDrawer(true)}
                    disableDiscovery={iOS}
                    >
                        {drawer}
                    </SwipeableDrawer>
                </Hidden> */}

                <Dialog 
                    fullScreen 
                    open={props.open} 
                    onClose={handleClose} 
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Chantiers
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {drawer}
                </Dialog>

                <Hidden smDown implementation="css">
                    <MUIDrawer
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    variant="permanent"
                    open>
                        {drawer}
                    </MUIDrawer>
                </Hidden>
                
            </nav>
        </>
    )
}
export default DrawerContainer

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
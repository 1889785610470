import React , { useEffect, useState } from "react"
import { makeStyles, useTheme } from '@material-ui/core/styles';

import MUIAppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { logout } from "../utils/auth"
import fire from "../utils/fire"
import { useLoggedInStatus } from "../utils/woodtools";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
		width: drawerWidth,
		flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('md')]: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
		    display: 'none',
		},
	},
	backButton: {
		marginRight: theme.spacing(2),
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	title: {
		flexGrow: 1
	},
	sup: {
		fontSize: "11px"
	},
	appBarButton: {
		[theme.breakpoints.down('sm')]: {
			padding: "7px",
        },
	},
}));

const Appbar = () => {
    const classes = useStyles();

	const [paramAnchor, setParamAnchor] = useState(null);
    const openOptionMenu = Boolean(paramAnchor);

    // const getAppbarText = () => (
    //     <Typography variant="h6" noWrap className={classes.title}>
    //         My-Forêt <sup className={classes.sup}>maps</sup>
    //     </Typography>
    // )

    // const getAppbarButton = () => (
    //     <div>
    //         <Hidden smUp>
    //             <IconButton
    //             color="inherit"
    //             edge="start"
    //             size="small"
    //             onClick={() => navigate(-1)}
    //             className={[classes.backButton, classes.appBarButton]}
    //             >
    //                 <ArrowBackIcon />
    //             </IconButton>
    //         </Hidden>
    //         <Hidden xsDown>
    //             <IconButton
    //             color="inherit"
    //             edge="start"
    //             onClick={() => navigate(-1)}
    //             className={classes.backButton}
    //             >
    //                 <ArrowBackIcon/>
    //             </IconButton>
    //         </Hidden>
    //     </div>
    // )

	const handleOpenParam = (e) => {
		setParamAnchor(e.currentTarget);
	};

	const handleCloseParam = () => {
		setParamAnchor(null);
	};

	const handleLogout = () => {
        handleCloseParam()
        logout()
        // dispatchNotification("Non disponible en mode démo.")
	}

    
	const isLoggedIn = useLoggedInStatus();

    return (
        <MUIAppBar position="fixed" className={classes.appBar}>
            <Toolbar>

                {/* {getAppbarButton()} */}
                
                {/* {getAppbarText()} */}
                <Typography variant="h6" noWrap className={classes.title}>
                    MY-Forêt <sup className={classes.sup}>maps</sup>
                </Typography>

                {isLoggedIn && <div>
                    <IconButton
                    onClick={handleOpenParam}
                    color="inherit">
                        <Hidden smUp>
                            <MoreVertIcon />
                        </Hidden>
                        <Hidden xsDown>
                            <AccountCircle />
                        </Hidden>
                    </IconButton>
                    <Menu
                    id="menu-appbar"
                    anchorEl={paramAnchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={openOptionMenu}
                    onClose={handleCloseParam}>
                        <MenuItem onClick={() => handleLogout()} >Se déconnecter</MenuItem>
                    </Menu>
                </div>}

            </Toolbar>
        </MUIAppBar>
    )
}
export default Appbar

import { useEffect, useState, useRef } from "react"
import { Marker, FeatureGroup } from 'react-leaflet';
import ChantierPopup from "../ChantierPopup";
import { makeStyles } from '@material-ui/core/styles';
import DrawerContainer from "./DrawerContainer";
import MyMap from "../MyMap";
import TypeSelect from "./TypeSelect"
import L from 'leaflet'
import markerAnthony from "../images/markerAnthony.png"
import markerDylan from "../images/markerDylan.png"
import markerGabin from "../images/markerGabin.png"
import CustomIcon from "./CustomIcon";
import shadow from "../images/marker-shadow.png"

import fire from "../utils/fire"

const useStyles = makeStyles((theme) => ({
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1
	},
    redMarker: {
        filter: "hue-rotate(139deg) brightness(1.1) contrast(1.2)"
    }
}));

const MainComponent = (props) => {
	const classes = useStyles();
    const [mapRef, setMapRef] = useState();
	const [markers, setMarkers] = useState([]);
    const markersRef = useRef([]);

    const [openDrawer, setOpenDrawer] = useState(window.location.hash === "#openDialog");
    const [currentType, setCurrentType] = useState("");
    const [chantiers, setChantiers] = useState([])
    const [stocks, setStocks] = useState([])
    const [currentChantiers, setCurrentChantiers] = useState([])
    const [currentStocks, setCurrentStocks] = useState([])

    // useEffect(() => {
    //     // TODO : soit on lit tous les stocks et on utilise en fonction du type OU on lit a chaque changement de type 
	// 	let stocksUnsubscribe = fire.firestore().collectionGroup("stocks").onSnapshot((snapshot) => {
	// 		let stockList = {};
    //         snapshot.forEach((doc) => {
	// 			if (stockList[doc.ref.parent.parent.id] === undefined) {
	// 				stockList[doc.ref.parent.parent.id] = []
	// 			}
    //             stockList[doc.ref.parent.parent.id][doc.id] = doc.data()
	// 		})
	// 		setStocks(stockList)
	// 	});
    //     return () => {
	// 		unsubscribe()
	// 		stocksUnsubscribe()
	// 	}
    // }, [])
    useEffect(()=> {
        const onHashChange = () => {
            switch(window.location.hash) {
                case "#openDialog":
                    setOpenDrawer(true)
                    break;
                default :
                    setOpenDrawer(false)
            }
        }
        window.addEventListener("popstate", onHashChange)
        window.addEventListener("hashchange", onHashChange)
        window.addEventListener("load", onHashChange)
        return () => {
            window.removeEventListener("hashchange", onHashChange);
        }
    }, []);

    useEffect(() => {
        // var query = fire.firestore().collection("chantiers")
        // if(currentType !== "CHAPE") {
        //     query = query.where("proprietaire", "==", "ARCHAMBEAU")
        // }
        var unsubscribe = fire.firestore().collection("chantiers")
        .onSnapshot((snapshot) => {
            let docs = [];
			snapshot.forEach((doc) => {
				docs.push({
                    idDoc: doc.id,
                    ...doc.data()
                })
			})
            docs.sort((a, b) => {
                try{
                    const [anneeA, numeroA] = a.numeroChantier.split('-').slice(1);
                    const [anneeB, numeroB] = b.numeroChantier.split('-').slice(1);
                    if (anneeA !== anneeB) {
                      return parseInt(anneeB) - parseInt(anneeA);
                    }
                    return parseInt(numeroB) - parseInt(numeroA);
                } catch(e) {
                    return 1;
                }
            })

			setChantiers(docs)
        })

        // var queryStock = fire.firestore().collectionGroup("stocks")
        // if(currentType !== "") {
        //     queryStock = queryStock.where("type", "==", currentType)
        // }
        var unsubStock = fire.firestore().collectionGroup("stock").onSnapshot((snap) => {
            let stockList = {};
            snap.forEach((doc) => {
				if (stockList[doc.ref.parent.parent.id] === undefined) {
					stockList[doc.ref.parent.parent.id] = []
				}
                stockList[doc.ref.parent.parent.id][doc.id] = doc.data()
			})
			setStocks(stockList)
        })
        return () => {
            unsubscribe()
            unsubStock()
        }
    }, [])

    useEffect(() => {
        if(currentType === "") {
            setCurrentChantiers(chantiers.filter((chantier) => !chantier.soldOut))
            setCurrentStocks(stocks)
            buildMarkers(chantiers.filter((chantier) => !chantier.soldOut), stocks)
        } else {
            let filteredStock = {}
            let filteredChantiers = []
            Object.entries(stocks).map(([numChantier, listeBois]) => {
                if(listeBois.hasOwnProperty(currentType) && !chantiers.find(x => x.idDoc === numChantier).soldOut) {
                    filteredStock[numChantier] = {
                        [currentType]: {...listeBois[currentType]}
                    }
                    filteredChantiers.push({
                        ...chantiers.find(x => x.idDoc === numChantier)
                    })
                }
            })
            filteredChantiers.sort((a, b) => {
                try{
                    const [anneeA, numeroA] = a.numeroChantier.split('-').slice(1);
                    const [anneeB, numeroB] = b.numeroChantier.split('-').slice(1);
                    if (anneeA !== anneeB) {
                      return parseInt(anneeB) - parseInt(anneeA);
                    }
                    return parseInt(numeroB) - parseInt(numeroA);
                } catch(e) {
                    return 1;
                }
            })
            setCurrentChantiers(filteredChantiers)
            setCurrentStocks(filteredStock)
            buildMarkers(filteredChantiers, filteredStock)
        }
    }, [chantiers, stocks, currentType])

    var anthonyIcon = new L.Icon({
        iconUrl: markerAnthony,
        shadowUrl: shadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    var dylanIcon = new L.Icon({
        iconUrl: markerDylan,
        shadowUrl: shadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    var gabinIcon = new L.Icon({
        iconUrl: markerGabin,
        shadowUrl: shadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    // var testIcon = new L.Icon({
    //     iconUrl: tmpMarker,
    //     iconSize: [41, 41],
    //     iconAnchor: [20.5, 41],
    //     popupAnchor: [0, -33],
    //     // shadowSize: [41, 41]
    // });

    const customMarker = (chantier) => {
        if(currentType === "") {
            switch(chantier.numeroChantier.charAt(0)) {
                case 'A':
                    return anthonyIcon
                case 'D':
                    return dylanIcon
                case 'G':
                    return gabinIcon
            }
        }
        let qte = 0
        if(stocks[chantier.idDoc]) {
            qte = stocks[chantier.idDoc][currentType].stock
        }

        let className = "myCluster"
        if(qte === 0) {
            className += " done"
        }
        if(qte.toString().length >= 5) {
            className += " bigCluster"
        }
        if(chantier.numeroChantier.charAt(0) === 'D') {
            className += " dylan"
        }
        if(chantier.numeroChantier.charAt(0) === 'G') {
            className += " gabin"
        }

        return L.divIcon({
            html: qte || "0",
            className: className,
            iconSize: null,
            popupAnchor: [0, -14],
        });
    }

    const buildMarkers = (chantiers, stocks) => {
        let markers = []
        chantiers && chantiers.map((val, i) => {
            if(!["", undefined].includes(val.latitude) && !["", undefined].includes(val.longitude) && !val.soldOut) {
                let position = [Number(val.latitude), Number(val.longitude)]
                // console.log(<CustomIcon chantier={val} stocks={stocks[val.idDoc]} currentType={currentType}/>)
                markers.push(
                    <Marker position={position}
                    icon={customMarker(val)}
                    // icon={<CustomIcon chantier={val} stocks={stocks[val.idDoc]} currentType={currentType}/>}
                    className={classes.redMarker}
                    key={val.idDoc}
                    ref={el => markersRef.current[val.idDoc] = el}
                    eventHandlers={{
                        click: (e) => {
                            //simple
                            // console.log('marker clicked', e)
                            let newPosition = [(val.latitude), val.longitude] //arr[0] + 0.006
                            e.target._map.flyTo(newPosition, e.target._map.getZoom())
                            console.log("click marker")
    
                            //flexible
                            // let px = e.target._map.project(e.target._popup._latlng);
                            // px.y -= e.target._popup._container.clientHeight/2;
                            // console.log(e.target._popup._container.clientHeight)
                            // e.target._map.panTo(e.target._map.unproject(px),{animate: true});
    
                            // setTimeout(() => {
                            //     var px = e.target._map.project(e.target._popup._latlng);
                            //     px.y -= e.target._popup._container.clientHeight/2;
                            //     console.log(e.target._popup._container.clientHeight)
                            //     e.target._map.flyTo(e.target._map.unproject(px), 14);
                            // }, 300)
                        },
    
                        // pas utile
                        // popupopen: (e) => {
                            // console.log("popup open", e)
                            // var px = e.target._map.project(e.target._popup._latlng);
                            // px.y -= e.popup._container.clientHeightt/2;
                            // console.log(px)
                            // e.target._map.flyTo(e.target._map.unproject(px), 14);
                            // e.target._map.panTo(e.target._map.unproject(px),{animate: true});
    
                            // setTimeout(() => {
                            //     var px = e.target._map.project(e.target._popup._latlng);
                            //     px.y -= e.target._popup._container.clientHeight/2;
                            //     console.log(e.target._popup._container.clientHeight)
                            //     e.target._map.flyTo(e.target._map.unproject(px), 14);
                            // }, 100)
                        // }
                    }}
                    >
                        <ChantierPopup 
                            chantier={val} 
                            stock={stocks[val.idDoc]}
                        />
                    </Marker>
                )
            }
        });
        setMarkers(markers)
    }

    const run = () => {
        console.log("RUN !")

        // let value = {
        //     "url": "https://goo.gl/maps/4Q4iiEPNNJJq3oHD8",
        //     "coordinates": "44.508934,0.9392891"
        // }
        // var regx = /[0-9]?[0-9]\.[0-9]*/g
        // console.log(value.coordinates.match(regx)[0])
        // console.log(value.coordinates.match(regx)[1])

        //putting chantiers
        // let chantiers = {}
        // Object.entries(chantiers).map(([key, val], i) => {
        //     fire.firestore().collection("chantiers").doc(key).set(val)
        //     .then(() => console.log("hit"))
        //     .catch((e) => console.log("miss", e))
        // })

        //puttin devis
        // let devis = {}
        // Object.entries(devis).map(([key, val], i) => {
        //     fire.firestore().collection("devis").doc(key).set(val)
        //     .then(() => console.log("hit"))
        //     .catch((e) => console.log("miss", e))
        // })

        // putting stock
        // let obj = {}
        // Object.entries(obj).map(([key, val], i) => {
        //     Object.entries(val).map(([type, data], j) => {
        //         fire.firestore().collection("chantiers").doc(key).collection("stock").doc(type)
        //         .set(data)
        //         .then(() => console.log("hit"))
        //         .catch((e) => console.log("miss", e))
        //     })
        // })

        // putting piles
        // let obj = {}
        // Object.entries(obj).map(([key, val], i) => {
        //     Object.entries(val).map(([idDoc, data], j) => {
        //         fire.firestore().collection("chantiers").doc(key).collection("piles").doc(idDoc)
        //         .set(data)
        //         .then(() => console.log("hit"))
        //         .catch((e) => console.log("miss", e))
        //     })
        // })
    }

    return (
        <>
            <TypeSelect
                getOpenDrawer={e => setOpenDrawer(e)}
                getType={val => setCurrentType(val)}
                type={currentType}
            />

            <DrawerContainer
                chantiers={currentChantiers}
                stocks={currentStocks}

                currentType={currentType}

                open={openDrawer}
                getOpenDrawer={e => setOpenDrawer(e)}

                markersRef={markersRef}
                mapRef={mapRef}
            />
            
            <main className={classes.content}>
				<div className={classes.toolbar} />

                {/* <button style={{zIndex: 999999, position: "absolute"}} onClick={run}>RUN</button> */}

                <MyMap getMapRef={(ref) => setMapRef(ref)} markers={markers} />
			</main>
        </>
    )
}
export default MainComponent
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth';

// TEST BASE
var config = {
    apiKey: "AIzaSyCmJ7BwPP-_hsGunl5RTHhyPlcWPg7OTWk",
    authDomain: "my-foret-app.firebaseapp.com",
    projectId: "my-foret-app",
    storageBucket: "my-foret-app.appspot.com",
    messagingSenderId: "35096605651",
    appId: "1:35096605651:web:89386f6581fc47ad21f526",
    measurementId: "G-HE04JD4376"
};


var fire = firebase.initializeApp(config);
fire.firestore()
    .enablePersistence({ synchronizeTabs: true })
    .catch(err => console.log(err));
    
export default fire;
import React , { useEffect, useState } from "react"
import fire from "./fire"

let refTable = {
    //chataigner
    "CHAPE" : {
        "categorie":"Chataîgner",
        "name": "Châtaignier perche",
        "cahierDesCharges": "longueur 2-10m, Ø > 5 cm",
        "stereUnit": 1,
        "color": "#FFEE58"
    },
    "CHABS" : {
        "categorie":"Chataîgner",
        "name": "Châtaignier Bois sec",
        "cahierDesCharges": "Petit bois sec, lg : 2m, Ø > 5cm",
        "stereUnit": 1,
        "color": "#FFEE58"
    },
    "CHAGS" : {
        "categorie":"Chataîgner",
        "name": "Châtaignier Gros sec",
        "cahierDesCharges": "Gros bois sec, lg : 2m, Ø > 12cm",
        "stereUnit": 1,
        "color": "#FFEE58"
    },
    "CHAPI" : {
        "categorie":"Chataîgner",
        "name": "Châtaignier Piquet",
        "cahierDesCharges": "Petit piquet droit, lg 1.8m-3m , Ø : 8-13cm",
        "stereUnit": 1,
        "color": "#FFEE58"
    },
    "CHAGP" : {
        "categorie":"Chataîgner",
        "name": "Châtaignier Gros piquet",
        "cahierDesCharges": "Gros piquet droit, lg 1.8m-3m , Ø : 14-25cm",
        "stereUnit": 1,
        "color": "#FFEE58"
    },
    "CHAME" : {
        "categorie":"Chataîgner",
        "name": "Châtaignier Merrain",
        "cahierDesCharges": "Bois sain, non roulé, lg 2m, Ø 14-25cm",
        "stereUnit": 1,
        "color": "#FFEE58"
    },
    "CHABI" : {
        "categorie":"Chataîgner",
        "name": "Châtaignier Bille",
        "cahierDesCharges": "Bois sain, non roulé, lg 2-3m, Ø > 25cm",
        "stereUnit": 0,
        "color": "#FFEE58"
    },
    "CHAGR" : {
        "categorie":"Chataîgner",
        "name": "Châtaignier Grume",
        "cahierDesCharges": "Bois sain, non roulé, droit, lg + 4m, Ø > 35cm",
        "stereUnit": 0,
        "color": "#FFEE58"
    },
    
    //chene
    "CHEPE" : {
        "categorie": "Chêne",
        "name": "Chêne Petit",
        "cahierDesCharges": "Petit Chêne et bois tordu, lg 2m, Ø > 7cm",
        "stereUnit": 1,
        "color": "#94f96c"
    },
    "CHECA" : {
        "categorie": "Chêne",
        "name": "Chêne calibré",
        "cahierDesCharges": "Chêne calibré droit, lg 2m, Ø > 12cm",
        "stereUnit": 1,
        "color": "#94f96c"
    },
    "CHETV" : {
        "categorie": "Chêne",
        "name": "Chêne tout venant",
        "cahierDesCharges": "Chêne chauffage tout venant, lg 2m, Ø > 7cm",
        "stereUnit": 1,
        "color": "#94f96c"
    },
    "CHETR" : {
        "categorie": "Chêne",
        "name": "Chêne traverse",
        "cahierDesCharges": "Bois sain, non gelé, droit, lg 2,6m, Ø > 28cm",
        "stereUnit": 0,
        "color": "#94f96c"
    },
    "CHECH" : {
        "categorie": "Chêne",
        "name": "Chêne charpente",
        "cahierDesCharges": "Bois sain, non gelé, droit, Nœud < 7cm, lg + 4m, Ø > 28cm",
        "stereUnit": 0,
        "color": "#94f96c"
    },
    "CHEAV" : {
        "categorie": "Chêne",
        "name": "Chêne avivé",
        "cahierDesCharges": "", // TODO : missing
        "stereUnit": 0,
        "color": "#94f96c"
    },
    "CHEME" : {
        "categorie": "Chêne",
        "name": "Chêne Merrain",
        "cahierDesCharges": "Bois sain, sans défauts, lg 1.2-8m , Ø > 40cm",
        "stereUnit": 0,
        "color": "#94f96c"
    },
    
    //charme
    "CHPET" : {
        "categorie": "Charme",
        "name": "Charme Petit",
        "cahierDesCharges": "Petit Charme et bois tordu, lg 2m, Ø > 7cm",
        "stereUnit": 1,
        "color": "#0adb73"
    },
    "CHCAL" : {
        "categorie": "Charme",
        "name": "Charme calibré",
        "cahierDesCharges": "Charme calibré droit, lg 2m, Ø > 12cm",
        "stereUnit": 1,
        "color": "#0adb73"
    },
    
    //Pin
    "PINPA" : {
        "categorie": "Pin",
        "name": "Pin Papeterie",
        "cahierDesCharges": "Papeterie, lg : 2m, Ø > 7cm",
        "stereUnit": 1,
        "color": "#FFB74D"
    },
    "PINCA" : {
        "categorie": "Pin",
        "name": "Pin Canter",
        "cahierDesCharges": "Bois sain, droit, lg 2-2.5m , Ø > 14cm",
        "stereUnit": 1,
        "color": "#FFB74D"
    },
    "PINCS" : {
        "categorie": "Pin",
        "name": "Pin caissage",
        "cahierDesCharges": "Bois sain, droit, Nœud < 7cm, lg 2-2.5m , Ø > 25cm",
        "stereUnit": 0,
        "color": "#FFB74D"
    },
    "PINME" : {
        "categorie": "Pin",
        "name": "Pin menuiserie",
        "cahierDesCharges": "Bois sain, droit, sans noeuds, lg 2-2.5m , Ø > 25cm",
        "stereUnit": 0,
        "color": "#FFB74D"
    },
    "PINTV" : {
        "categorie": "Pin",
        "name": "Pin tout venant",
        "cahierDesCharges": "Bois sain, Nœud < 5cm, lg 2.5m , Ø > 25cm",
        "stereUnit": 0,
        "color": "#FFB74D"
    },
    
    //peuplier
    "PEPAL" : {
        "categorie": "Peuplier",
        "name": "Peuplier Palette",
        "cahierDesCharges": "Bois sain, droit, Nœud < 10cm, lg 2-2.5m , Ø > 25cm",
        "stereUnit": 0,
        "color": "#6dd0fe"
    },
    "PESCI" : {
        "categorie": "Peuplier",
        "name": "Peuplier Sciage",
        "cahierDesCharges": "Bois sain, droit, Nœud < 5cm, lg 2-2.5m , Ø > 25cm",
        "stereUnit": 0,
        "color": "#6dd0fe"
    },
    "PEDER" : {
        "categorie": "Peuplier",
        "name": "Peuplier Déroulage",
        "cahierDesCharges": "Bois sain, non fendu, Nœud < 5cm, lg 2-2.5m , Ø > 25cm",
        "stereUnit": 0,
        "color": "#6dd0fe"
    },
        
    //acacia
    "ACAPT" : {
        "categorie": "Acacia",
        "name": "Acacia petit tordu",
        "cahierDesCharges": "Petit acacia et bois tordu, lg 2m, Ø > 7cm",
        "stereUnit": 1,
        "color": "#cdb3ff"
    },
    "ACAPI" : {
        "categorie": "Acacia",
        "name": "Acacia Piquet",
        "cahierDesCharges": "Petit Piquet, droit, lg 1.8m-3m , Ø : 8-30cm",
        "stereUnit": 1,
        "color": "#cdb3ff"
    },
    "ACABI" : {
        "categorie": "Acacia",
        "name": "Acacia Bille",
        "cahierDesCharges": "Bille Acacia, droit, lg 2-2.5m , Ø > 30cm",
        "stereUnit": 0,
        "color": "#cdb3ff"
    },

    //Papeterie Feuillu
    "CHAPA" : {
        "categorie":"Papeterie Feuillu",
        "name": "Châtaignier Papeterie",
        "cahierDesCharges": "Papeterie, lg : 2m, Ø > 7cm",
        "stereUnit": 1,
        "color": "#85f0ff"
    },
    "PEPAP" : {
        "categorie": "Papeterie Feuillu",
        "name": "Peuplier Papeterie",
        "cahierDesCharges": "Papeterie, lg : 2m, Ø > 7cm",
        "stereUnit": 1,
        "color": "#85f0ff"
    },
    "ACAPA" : {
        "categorie": "Papeterie Feuillu",
        "name": "Acacia Papeterie",
        "cahierDesCharges": "",
        "stereUnit": 0,
        "color": "#85f0ff"
    },
    "CHPAP" : {
        "categorie": "Papeterie Feuillu",
        "name": "Charme Papeterie",
        "cahierDesCharges": "",
        "stereUnit": 1,
        "color": "#85f0ff"
    },

    //Gros Papier
    "GROPA" : {
        "categorie":"Gros Papier",
        "name": "Gros papier" ,
        "cahierDesCharges": "Papeterie, lg : 2m, Ø > 50cm",
        "stereUnit": 1,
        "color": "#ff7b7b"
    },

    //autre
    "AUTRE" : {
        "categorie": "Autre",
        "name": "Autre",
        "cahierDesCharges": "",
        "stereUnit": 1,
        "color": "#ccaa97"
    },
    "STDST" : {
        "categorie": "Autre",
        "name": "Standard St",
        "cahierDesCharges": "",
        "stereUnit": 1,
        "color": "#ccaa97"
    },
    "STDST_2" : {
        "categorie": "Autre",
        "name": "Standard St",
        "cahierDesCharges": "",
        "stereUnit": 1,
        "color": "#ccaa97"
    },
    "STDST_3" : {
        "categorie": "Autre",
        "name": "Standard St",
        "cahierDesCharges": "",
        "stereUnit": 1,
        "color": "#ccaa97"
    },
    "STDST_4" : {
        "categorie": "Autre",
        "name": "Standard St",
        "cahierDesCharges": "",
        "stereUnit": 1,
        "color": "#ccaa97"
    },
    "STDM3" : {
        "categorie": "Autre",
        "name": "Standard m³",
        "cahierDesCharges": "",
        "stereUnit": 0,
        "color": "#ccaa97"
    },
    "STDM3_2" : {
        "categorie": "Autre",
        "name": "Standard m³",
        "cahierDesCharges": "",
        "stereUnit": 0,
        "color": "#ccaa97"
    },
    "STDM3_3" : {
        "categorie": "Autre",
        "name": "Standard m³",
        "cahierDesCharges": "",
        "stereUnit": 0,
        "color": "#ccaa97"
    },
    "STDM3_4" : {
        "categorie": "Autre",
        "name": "Standard m³",
        "cahierDesCharges": "",
        "stereUnit": 0,
        "color": "#ccaa97"
    }
}

/**
 * Trie la liste refTable par catégories.
 * @returns La liste refTable triée par catégories
 */
 export const getWoodsGroupByCategories = () => {
    let sorted = {}
    Object.entries(refTable).map(([wood, data], i) => {
        if(sorted[data.categorie] === undefined) {
            sorted[data.categorie] = [];
        }
        sorted[data.categorie][wood] = data
    })
    // console.log(sorted)
    return sorted
}

export const getUnit = (ref, short) => {
    if (typeof short === 'undefined') { short = false }
    if(ref in refTable){
        if(refTable[ref].stereUnit === 1) {
            if(short){
                return "St"
            } else {
                return "Stères"
            }
        } else {
            return "m³"
        }
    } else {
        return "-" //dont exist
    }
}

export const useLoggedInStatus = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    useEffect(() => {
        let unsub = fire.auth().onAuthStateChanged((user) => {
			setIsLoggedIn(user)
		})
        return () => unsub()
    }, [])
    return isLoggedIn
}
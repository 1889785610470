import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Popup } from 'react-leaflet';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import RoomIcon from '@material-ui/icons/Room';
import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import { getUnit } from "./utils/woodtools"

const useStyles = makeStyles((theme) => ({
    chantierNumber: {
      fontWeight: "bold"
    },
    unit: {
      fontWeight: "bold",
      fontSize: "14px"
    },
    link: {
        textDecoration: "none"
    }
}));

function ChantierPopup(props) {
    const classes = useStyles();
    // console.log(props.stock)

    const getUrl = (lat, long) => ("https://www.google.com/maps/search/?api=1&query="+Number(lat)+","+Number(long)).replaceAll(" ", "")
    const copyLink = (link) => {
        navigator.clipboard.writeText(link)
        // setOpenSnackbar(true)
    }

    const numChantierWithColor = (num) => {
        let color = "#169dff"
        let manager = num.charAt(0)
        if(manager === "D")
            color = "#f3830d"
        return <><span style={{color: color}}>{manager}</span>{num.slice(1)}</>
    }

    // console.log(props.chantier.localisation)
    // console.log(props.chantier.proprietaire)
    return (
        <Popup closeButton={false} minWidth={240} maxWidth={240} autoPan={false}>
            <Grid container spacing={1} justify="center">
                <Grid item xs={12}>
                    <Grid item xs={12} container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Typography variant="h5" className={classes.chantierNumber}>{numChantierWithColor(props.chantier.numeroChantier)}</Typography>
                            {/* <Typography variant="h5" className={classes.chantierNumber}>{props.chantier.numeroChantier || "???"}</Typography> */}
                        </Grid>
                        <Grid item>
                            <IconButton color="secondary" component="span" size="small" onClick={() => copyLink(getUrl(props.chantier.latitude,props.chantier.longitude))}>
                                <LinkIcon />
                            </IconButton>
                            
                            {/* <Button
                            size="small"
                            disableElevation
                            variant="outlined" 
                            color="secondary"
                            startIcon={<LinkIcon/>}>
                                lien
                            </Button> */}
                        </Grid>
                    </Grid>

                    <Typography variant="caption" gutterBottom>
                        <b style={{textTransform: "uppercase"}}>{props.chantier.proprietaire}</b>
                        {props.chantier.proprietaire.length+props.chantier.localisation.length > 35 && <br/>}
                        {props.chantier.proprietaire!=="" && props.chantier.localisation!=="" && props.chantier.proprietaire.length+props.chantier.localisation.length <= 35 && " - "}
                        {props.chantier.localisation}
                    </Typography>
                    
                </Grid>

                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12} container justify="center" spacing={0}>
                    {props.stock && Object.entries(props.stock).map(([type, data], i) => (
                        <Grid item xs={12} container justify="center" spacing={0} key={type}>
                            <Grid item xs container justify="flex-start" spacing={0}>
                                <Grid item>
                                    <Typography variant="subtitle2"  className={classes.chantierNumber}>[{type}]</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} container justify="center" spacing={0}>
                                <Grid item container justify="flex-start" spacing={1}>
                                    <Grid item>
                                        <Typography variant="subtitle2"  className={classes.chantierNumber}>{data.stock}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2">/</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2">{data.volumeInitial} <span className={classes.unit}> {getUnit(type, true)}</span></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))} 
                </Grid>

                {/* {props.stock && Object.entries(props.stock).map(([type, data]) => (
                        <Grid item xs={12} container justify="flex-start" spacing={1}>
                            <Grid item>
                                <Typography variant="subtitle2"  className={classes.chantierNumber}>[{type}]</Typography>
                            </Grid>
                            <Grid item xs container justify="flex-start" spacing={1}>
                                <Grid item>
                                    <Typography variant="subtitle2"  className={classes.chantierNumber}>{data.stock}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2">/</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2">{data.volumeInitial} <span className={classes.unit}> {getUnit(type, true)}</span></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                )} */}

                {/* <Grid item xs={12}>
                    <Button variant="outlined" fullWidth onClick={() => setOpen(!open)}>{ open ? "Cacher": "Montrer" } piles</Button>
                </Grid>

                <Grid item xs={12} >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Grid container justify="center" spacing={0}>
                            <Grid item xs={12}>
                                <Typography variant="button"  className={classes.chantierNumber}>Pile 1 : 50 St</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="button"  className={classes.chantierNumber}>Pile 1 : 50 St</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="button"  className={classes.chantierNumber}>Pile 1 : 50 St</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="button"  className={classes.chantierNumber}>Pile 1 : 50 St</Typography>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid> */}

                <Grid item xs={12} container justify="center" alignItems="center" spacing={1}>
                    <Grid item xs={12}>
                        <Link underline="none" href={getUrl(props.chantier.latitude,props.chantier.longitude)} rel="noopener" target="_blank" className={classes.link}>
                            <Button
                            disableElevation
                            variant="contained" 
                            color="primary" 
                            fullWidth 
                            endIcon={<RoomIcon/>}>
                                Google Maps
                            </Button>
                        </Link>
                    </Grid>
                    {/* <Grid item xs container justify="flex-end">
                        <Button
                        size="small"
                        disableElevation
                        variant="outlined" 
                        color="secondary"
                        startIcon={<LinkIcon/>}>
                            copier lien
                        </Button>
                        <IconButton color="secondary" component="span">
                            <LinkIcon />
                        </IconButton>
                    </Grid> */}
                </Grid>

                {/* <Grid item xs={12} container justify="center" alignItems="center" spacing={1}>
                    <Grid item xs>
                        <Link underline="none" href={"https://www.google.com/maps/search/?api=1&query="+props.chantier.latitude+","+props.chantier.longitude} rel="noopener" target="_blank" className={classes.link}>
                            <IconButton color="primary" component="span" size="small">
                                <RoomIcon/>
                            </IconButton>
                        </Link>
                    </Grid>
                    <Grid item>
                        <IconButton color="secondary" component="span" size="small">
                            <LinkIcon />
                        </IconButton>
                    </Grid>
                </Grid> */}
            </Grid>
            
        </Popup>
    )
}

export default ChantierPopup;